import React, { useState, useEffect } from "react";
import { motion, useAnimation } from "framer-motion";

const HeroBanner = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const elements = [
    { image: require("../../../assets/img1q.webp"), text: "" },
    { image: require("../../../assets/img2q.webp"), text: "" },
    // { image: require("../../../assets/3_Banner_8b649a21ad.jpeg"), text: "Element 3" },
  ];
  const mainControl = useAnimation();

  useEffect(() => {
    console.log("Triggering animation");
    const intervalId = setTimeout(() => {
      const nextIndex = (currentIndex + 1) % elements.length;
      setCurrentIndex(nextIndex);
      console.log("Animating to next index:", nextIndex);
    }, 4000);

    return () => clearTimeout(intervalId);
  }, [currentIndex, elements.length, mainControl]);

  console.log("Main control:", mainControl);

  return (
    <div className="App ">
<a href="/check">
        <header
        className="h-screen new-App-header "
        style={{
          backgroundImage: `url(${elements[currentIndex].image})`,
          backgroundSize: "cover",
        }}
        >
        <div className="text-overlay h-full flex items-center justify-end text-white p-11">
          {elements[currentIndex].text}
        </div>
        </header>
        </a>
    </div>
  );
};

export default HeroBanner;
