import ItemCard from "../../../components/itemCard";
import NosokService from "../../../components/nosokService";

const ThirdSection = () => {
  return (
    <div className="App">
      <header className="App-header w-screens">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 w-screen p-5" style={{direction:"rtl"}}>
          <NosokService
            image={require("../../../assets/kaapa.webp")}
            title={"إصدار تصريح اداء العمرة"}
            description={"تصريح لاداء العمرة"}
          />
          <NosokService
            image={require("../../../assets/tasreeh.webp")}
            title={"إصدار تصريح الصلاة في الروضة"}
            description={"خدمة تمكنك من إصدار تصريح للصلاة في الروضة الشريفة"}
          />
          <NosokService
            image={require("../../../assets/hagelkharej.webp")}
            title={"حج للخارج"}
            description={"خدمة تمكنك من حجز باقة حج الزوار"}
          />
          <NosokService
            image={require("../../../assets/reports.webp")}
            title={"البلاغات"}
            description={"خدمة تمكنك من تقديم اي شكاوي او مقترحات او استفسارات"
}
          />
        </div>
      </header>
    </div>
  );
};

export default ThirdSection;
