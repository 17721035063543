import React from 'react';
import HeroBanner from './Sections/heroBanner';
import { useOutletContext } from 'react-router-dom';
import SecoundSection from './Sections/section2Banner';
import ThirdSection from './Sections/Section3Banner';
import FourthSection from './Sections/Section4Banner';
import FifthSection from './Sections/Section5Banner';
import ImageWithText from './Sections/new';
import FullscreenImage from '../../components/productIMA';

const Home = () => {
  const { firstSectionRef } = useOutletContext();

  return (
    <div className="App-header">
      <div className='w-full' ref={firstSectionRef}>
        <HeroBanner />
      </div>
      {/* <ImageWithText imageUrl={"../../assets/philipsDevice1.png"} text={"hello in ppp"}/> */}
      
      {/* <FullscreenImage imageUrl={require("../../assets/philipsDevice1.png")} /> */}
      <ThirdSection/>
      <SecoundSection/>
      {/* <FourthSection/> */}
      {/* <FifthSection/> */}

    </div>
  );
};

export default Home;
