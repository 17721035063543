import React from 'react';

const CheckPage = () => {
  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
      <h1 className="mb-4 text-xl font-semibold text-gray-700">يرجى إدخال رقم جواز السفر أو الرقم القومي</h1>
      <input
        type="text"
        placeholder="ابحث هنا..."
        className="w-full max-w-xs p-3 text-gray-700 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
      />
    </div>
  );
}

export default CheckPage;
