import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';
import FullscreenImage from "../components/productIMA";

const Results = () => {
  const { id } = useParams();
  const [imageUrl, setImageUrl] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchImage = async () => {
      try {
        const response = await axios.get(`https://nosok-backend.onrender.com/GetNosokResult/${id}`);
        setImageUrl(response.data.image_nosok_path);  // Setting the image URL from the API response
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchImage();
  }, [id]);

  if (loading) return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <CircularProgress />
    </div>
  );
  if (error) return <div>Error: {error.message}</div>;

  return (
    <div className="bg-white min-h-[600px]">
     <div className="grid grid-cols-1 md:grid-cols-2 gap-4 p-11" style={{direction:"rtl"}}>
        <div className="flex items-center justify-center"><FullscreenImage imageUrl={imageUrl} /></div>
        <div className="align-right">
            {/* <button className="bg-[#D7AE78]">أضف لسلة التسوق</button> */}
            <br/>
            <button type="button" className="focus:outline-none text-white bg-[#D7AE78]  hover:bg-[#F09042] focus:ring-4 focus:ring-yellow-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 "><i className="fa-solid fa-bag-shopping mx-2"></i> اضف لسلة التسوق </button>

        </div>
     </div>
      {/* <h1>Result Details for ID: {id}</h1> */}
      
    </div>
  );
}

export default Results;
