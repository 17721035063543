import React, { useRef } from 'react';
import { Outlet } from 'react-router-dom';
import NosokNav from '../components/nosoknavbar';
import Footer from '../components/footer';

const Layout = () => {
  const firstSectionRef = useRef();

  return (
<div className='bg-[#f2f2f2]'>
      <header >
<NosokNav/> 
      </header>
      <main >
        <Outlet context={{ firstSectionRef }} />
      </main>
      <footer>
        <Footer/>
      </footer>
      </div>
  );
};

export default Layout;
