import React from 'react';
import RoutesProvides from './providers/routing';
import './App.css'
import AppProviders from './providers/providersMain';
function App() {
  return (
<AppProviders>
<RoutesProvides/>
</AppProviders>
  );
}

export default App;
