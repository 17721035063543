import React from 'react';
import { Outlet } from 'react-router-dom';

function About() {
  return (
    <div>
      <h1>About Us</h1>
      <Outlet /> {/* Placeholder for nested routes */}
    </div>
  );
}

export default About;
