import React from 'react';

function Footer() {
  return (
    <div className="bg-white py-6 my-5 text-center">
      <div className="flex justify-center space-x-4">
        <Icon icon="fa-brands fa-facebook-f" />
        <Icon icon="fa-brands fa-instagram" />
        <Icon icon="fa-brands fa-whatsapp" />
      </div>
    </div>
  );
}

function Icon({ icon }) {
  return (
    <div className="w-12 h-12 flex items-center justify-center rounded-full bg-[#22262B] text-white">
      <i className={`text-xl fab ${icon}`} />
    </div>
  );
}

export default Footer;
