

const SecoundSection = () =>{
    return(
        <div className="App">
        <header className="App-header p-0  bg-[#22262B]" style={{direction:"rtl"}}>
          <div className="grid md:grid-cols-6 grid-cols-1 gap-4 text-left">
            <div></div>
            <div className="col-span-2 text-right text-white">
            <h3>نسك حج <br/> بوابتك لرحلة العمر</h3>
            <br/>
          <p className="line-height-5 text-right text-white">
          نسك حج هي منصة شاملة تسهل رحلة حجاج بيت الله من ١٢٦ دولة محددة.

تقدم المنصة مجموعة متنوعة من باقات الحج لضمان تجربة حج سلسة وميسرة، وذلك عن طريق مقدمي الخدمات المعتمدين.            </p>
            </div>
            <div className="w-100 col-span-2 h-full flex items-center justify-center">
          <img src={require("../../../assets/section2.webp")} className="h-100 w-100"/>
            </div>
          </div>
         </header>
      </div>
    )
}


export default SecoundSection;