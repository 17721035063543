import React, { useRef, useState, useEffect } from 'react';

const FullscreenImage = ({ imageUrl }) => {
  const [isFullscreen, setIsFullscreen] = useState(false);
  const imgRef = useRef(null);
  const fullscreenRef = useRef(null);

  const enterFullscreen = () => {
    const elem = fullscreenRef.current;
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) { // Firefox
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) { // Chrome, Safari and Opera
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) { // IE/Edge
      elem.msRequestFullscreen();
    }
    setIsFullscreen(true);
  };

  const exitFullscreen = () => {
    if (document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement || document.msFullscreenElement) {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) { // Firefox
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) { // Chrome, Safari and Opera
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) { // IE/Edge
        document.msExitFullscreen();
      }
      setIsFullscreen(false);
    }
  };

  const handleFullscreenChange = () => {
    setIsFullscreen(!!(document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement || document.msFullscreenElement));
  };

  useEffect(() => {
    document.addEventListener('fullscreenchange', handleFullscreenChange);
    document.addEventListener('webkitfullscreenchange', handleFullscreenChange);
    document.addEventListener('mozfullscreenchange', handleFullscreenChange);
    document.addEventListener('MSFullscreenChange', handleFullscreenChange);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
      document.removeEventListener('webkitfullscreenchange', handleFullscreenChange);
      document.removeEventListener('mozfullscreenchange', handleFullscreenChange);
      document.removeEventListener('MSFullscreenChange', handleFullscreenChange);
    };
  }, []);

  return (
    <div className='w-full'>
      <div className='flex flex-col-reverse md:grid md:grid-cols-3 md:gap-2 ' >
        <div className='flex flex-col-reverse md:grid md:grid-cols-2 md:gap-2'>
<div className='w-full  flex items-start justify-center'>


        <div className='border border-solid flex items-center justify-center  ' style={{width:"100px" , height:"100px"}}>
      <img
        src={imageUrl}
        alt="Product"
        style={{ width: '40px' }}
        ref={imgRef}
      />
      </div>

      </div>
      <div className='flex items-end justify-start md:justify-end'>

      <button onClick={enterFullscreen} className='my-[10px] p-[10px] md:p-[0px] md:my-[0px]'>
      <i className="fa-solid fa-expand text-2xl hover:text-3xl"></i>
      </button>

      </div>
        </div>
      <div className='md:col-span-2 flex items-center justify-center md:justify-start'>
      <img
        src={imageUrl}
        alt="Product"
        style={{ width: '150px' }}
        ref={imgRef}
      />

      </div>
      </div>
      <div
        ref={fullscreenRef}
        style={{
          display: isFullscreen ? 'flex' : 'none',
          backgroundColor: 'white',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          width: '100%',
          position: 'fixed',
          top: 0,
          left: 0,
          zIndex: 1000,
          flexDirection: 'column',
        }}
      >
        <img
          src={imageUrl}
          alt="Product"
          style={{ width: '90%', height: '90%', objectFit: 'contain' }}
        />
        <div className='relative'>

        
        <button
          onClick={exitFullscreen}
          className='absolute md:top-[-30px] md:left-[200px]'

        >
                <i className="fa-solid fa-expand text-2xl hover:text-3xl"></i>

        </button>
        </div>
      </div>
    </div>
  );
};

export default FullscreenImage;
