const NosokNav = () =>{
return(
    <div className="bg-[#22262B] text-white px-11 py-4">
        <div className="grid grid-cols-3 gap-4 " style={{direction:"rtl"}}>
            <div className="flex items-center"><a href="/"><img className="w-16" src={require("../assets/logo.webp")}/> </a></div>
            <div></div>
            <div className="flex items-center justify-center">ع</div>
        </div>
    </div>
)
}

export default NosokNav;