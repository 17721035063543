import React from 'react';

const NosokService = ({image,title,description}) => {
  return (
    <div className="w-full p-6 bg-white border border-gray-200 rounded-lg shadow dark:border-gray-200" style={{direction:"rtl"}}>
      {/* Image like icon */}
      <div className="flex justify-start" >
        <img 
          src={image} 
          alt="icon" 
          className="w-24 h-24 mb-2"
        />
      </div>

      <a href="#">
        <h5 className="mb-2 text-2xl font-semibold tracking-tight text-[#D3B07C] text-right">{title}</h5>
      </a>
      <p className="mb-3 font-normal text-[#D3B07C] text-right">{description}</p>
    </div>
  );
};

export default NosokService;
