import React from 'react';
import { lazy, Suspense } from 'react-router-dom'; // Import from react-router-dom
import Home from './page/Home/home';
import App from './App';
import About from './page/about'; // Import About normally
import Layout from './layout/mainlayout';
import ProductDetails from './page/productDetails';
import AddUserForm from './page/Adduser';
import Results from './page/results';
import ViewUsers from './page/viewUsers';
import CheckPage from './page/CheckPage';
import Control from './page/productDetails';


const Routes = [
    {
      path: '/',
      element: <Layout />,
      children: [
        { path: '/', element: <Home /> },
        {
          path: 'result', // Remove the leading slash here
          children: [
            { path: ':id', element: <Results/> } // Remove the leading slash here
          ]
        },
        {
          path: 'control', // Remove the leading slash here
          // element:(<Control/>),
          children: [
            { path: 'adduser', element: <AddUserForm /> }, // Remove the leading slash here
            { path: 'users', element: <ViewUsers /> } // Remove the leading slash here

          ]
        },
        {
          path: 'check', // Remove the leading slash here
          element: (
            <CheckPage/>
          ),
        },
      ],
    },
  ];
  

export default Routes;
