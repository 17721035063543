import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Paper 
} from '@mui/material';

const ViewUsers = () => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    axios.get('https://nosok-backend.onrender.com/getusers')
      .then(response => {
        setUsers(response.data);
      })
      .catch(error => {
        console.error("There was an error fetching the users!", error);
      });
  }, []);

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>

            <TableCell>Copy ID</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>User ID</TableCell>
            <TableCell>Birth Date</TableCell>
            <TableCell>Company</TableCell>
            <TableCell>Company ID</TableCell>
            <TableCell>Get Image</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users.map((user) => (
              <TableRow key={user._id}>
              <TableCell>{user.CopyId}</TableCell>
              <TableCell>{user.name}</TableCell>
              <TableCell>{user.userId}</TableCell>
              <TableCell>{user.BirthDate}</TableCell>
              <TableCell>{user.Company}</TableCell>
              <TableCell>{user.CompanyId}</TableCell>
              <TableCell><a href={`https://nosok-backend.onrender.com/generate-image/${user.userId}`} className='bg-[#22262B] text-white px-5 py-2 rounded hover:bg-[#22262bb6]'>get Image</a> </TableCell>

            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ViewUsers;
